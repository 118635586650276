import {
    Component,
    Input,
    Optional,
    Host,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewChild,
    ElementRef
} from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';
import { filter } from 'rxjs/operators';
import { isNumeric } from 'rxjs/internal-compatibility';
import { AgEditorComponent } from 'ag-grid-angular';

@Component({
    selector: 'hc-inline-number',
    styleUrls: ['inline-number.component.scss'],
    templateUrl: 'inline-number.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineNumberComponent implements OnInit, AgEditorComponent {

    @ViewChild('editValInput', {static: true}) inputElement: ElementRef<HTMLInputElement>;
    // @ViewChild('editVal') inputElement: ElementRef;

    /** Form model for the input. */
    public editVal;

    constructor(
    ) { }

    ngOnInit() {
    }

    public agInit(params) {
        this.editVal = params.value;
        setTimeout( () => {
            this.inputElement.nativeElement.focus();
            this.inputElement.nativeElement.select();
          }, 10 );
    }

    public getValue(): any {
        return this.editVal;
    }

    public isPopup(): boolean {
        return false;
    }
}
