import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ChangeDetectorRef,
    OnDestroy,
    ChangeDetectionStrategy
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Report } from './model/report.model';
import { ReportService } from '../../shared/report-service';
import { Globals } from '../../common/globals';
import { ReportResult } from './model/report-result.model';
import { ReportGridSetup } from './tools/report-grid-setup';
import {
    GridOptions,
    ColDef,
} from '@ag-grid-enterprise/all-modules';
import { FilterEditorType } from './model/filter-editor-type.enum';
import { ReportFilter } from './model/report-filter.model';
import { TranslationService } from 'src/app/shared/translation.service';
import { SavingDialogComponent }  from '../saving-dialog.component';
import { ReportGridHandler } from './tools/report-grid-handler';
import { InfoDialogComponent } from 'src/app/common/dialog.info.component';
import { SavedReport } from './model/saved-report.model';
import { SavedReportContent } from './model/saved-report-content.model';
import { InputDialogComponent } from '../dialog-input.component';
import { InputDialogContent } from '../dialog-input-content.model';
import { DeleteDialogComponent } from '../dialog.delete.component';
import { PrintDialogComponent } from '../dialog.print.component';
import { PrintDialogContent } from '../dialog.print.content.model';
import { FilterPurpose } from '../filter-purpose.enum';
import { ElasticFilterRequest } from '../elastic-filter-request.model';
import { BulkInsertTableResult } from './model/bulk-insert-table-result.model';
import { ReportVariables } from './model/report-variables-model';
import { Observable } from 'rxjs';
import { ReportDataModel } from './model/report-data-model.enum';
import { MediaService } from 'src/app/shared/media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ProductListItem } from '../product.model';
import { UserListItem } from '../user.model';
import { DateRange } from '../date-range.model';
import { DateRangeEnum } from '../date-range.enum';
import { AuthService } from 'src/app/shared/auth.service';
import { ReportType } from './model/report-type.enum';
import { ClipboardModule } from '@ag-grid-enterprise/all-modules';
import { AGGridLicenser } from './tools/ag-grid-license';
import { MatDialog } from '@angular/material/dialog';
import { Honeycomb } from 'src/app/shared/honeycomb-api/honeycomb-api';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

export const LastSavedReportPefix = 'internal_last_saved_state_';

@Component({
    selector: 'hc-report-grid',
    templateUrl: './report-grid.component.html',
    styleUrls: ['./report-grid.component.scss'],
    providers: [DatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ReportGridComponent implements OnInit, OnDestroy {
    @Input() public reportName: string;
    @Input() public showSaveReport = false;
    @Input() public filterPurpose = FilterPurpose.undefined;
    @Input() public variables = {};
    @Input() public fixedFilterTable: BulkInsertTableResult;

    // called immediatelly the options object exists, but prior it's set to the grid
    @Output() public onOptionsInitialized: EventEmitter<GridOptions> = new EventEmitter();
    // before the columns are build, but before they are set to the grid
    @Output() public onBeforeColumnsInitialized: EventEmitter<Function> = new EventEmitter();
    // after the columns are build, but before they are set to the grid
    @Output() public onColumnsInitialized: EventEmitter<ColDef[]> = new EventEmitter();
    // after the grid is fully build and ready
    @Output() public onGridInitialized: EventEmitter<any> = new EventEmitter();
    // after the grid is fully build and ready
    @Output() public onReportFiltersSetup: EventEmitter<any> = new EventEmitter();
    // when the report current state (layout, etc.) is saved
    @Output() public onReportSaved: EventEmitter<SavedReport> = new EventEmitter();
    // when the saved layout is deleted
    @Output() public onSavedReportDeleted: EventEmitter<SavedReport> = new EventEmitter();
    // when a row selection (via checkbox) is changed
    @Output() public onSelectedRowsChanged: EventEmitter<any> = new EventEmitter();
    // when deleting all or selected rows via context menu
    @Output() public onDelete: EventEmitter<any> = new EventEmitter();

    public reportLoaded = false;
    public report: Report;
    public reportGridHandler: ReportGridHandler;
    public gridOptions: GridOptions;
    public paginationPageSize = 1000;

    public editorType = FilterEditorType;

    private lastFulltextSearch = null;
    private lastFilterRequest: ElasticFilterRequest;
    private currentFilterRequest: ElasticFilterRequest;

    public pageSizes: any[];
    public modules: any[] = [ClipboardModule];
    // this is at the moment the only way to have the clipboard working

    public currentRecordCount = 0;
    public dataLoadedErrorMessage = '';

    public showInitChooseFilterMessage = false;
    private savedReport: SavedReport;
    private currentBulkTable: BulkInsertTableResult;
    private onInitializeReportRun = false;

    public hasRecordCount: boolean;

    public defaultColDef;

    constructor(
        public reportService: ReportService,
        public mediaService: MediaService,
        public datePipe: DatePipe,
        public trans: TranslationService,
        public gridSetup: ReportGridSetup,
        public sanitizer: DomSanitizer,

        private globals: Globals,
        private changeRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private authService: AuthService,
        private gridLic: AGGridLicenser
    ) {
        this.defaultColDef = {
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            resizable: true,
            sortable: true,
            filter: true
          };
    }

    async ngOnInit() {
        this.gridLic.setAGGridLicense();
        this.report = new Report();
        this.pageSizes = this.gridSetup.getPageSize();
        this.fillDefaultVariables();
        this.loadReport();
        if (this.onGridInitialized) {
            this.onGridInitialized.emit(this);
        }
    }

    async ngOnDestroy() {
        this.destroyTempTable();
    }

    public get currentRecordCountFormated(): string {
        if (!this.currentRecordCount) {
            return '';
        }
        if ((this.report.reportDataModel === ReportDataModel.clientSide) && (this.report.maxRecordCount > 0)) {
            return this.currentRecordCount + ' '
            + this.trans.instant('admin.web.rep.shortMax')
            + ' (' + this.report.maxRecordCount.toString() + ')';
        }
        return this.currentRecordCount.toString();
    }

    public onDateRangeChanged(filter: ReportFilter) {
        this.setFilterDateRangeValues( filter );
    }

    private setFilterDateRangeValues(filter: ReportFilter) {
        filter.value1 = this.datePipe.transform( filter.dateRange.since, 'yyyy-MM-dd');
        filter.value2 = this.datePipe.transform( filter.dateRange.till, 'yyyy-MM-dd');
    }

    public visibleFilters(): ReportFilter[] {
        if (!this.report) {
            return [];
        }
        return this.report.reportFilters.filter( r => !r.hidden);
    }

    public loadReport(reportName: string = null,
                      savedReport: SavedReport = null,
                      reportLoadedCallback: Function = null,
                      gridInitializedCallback: Function = null) {
        this.dataLoadedErrorMessage = '';
        this.currentRecordCount = 0;
        if (reportName) {
            this.reportName = reportName;
        }
        if (!this.reportName) {
            return;
        }

        this.savedReport = savedReport;
        this.reportLoaded = false;
        let cloakRef = this.dialog.open(SavingDialogComponent);
        this.reportService.getReportByName(this.reportName).subscribe(r => {
            cloakRef.close();
            this.report = r;
            if (r.maxRecordCount > 0) {
                this.hasRecordCount = true;
            }
            if (!this.filterPurpose) {
                this.filterPurpose = this.report.advancedFilterPurpose;
            }

            this.reportGridHandler = new ReportGridHandler(this,
                                                           this.report,
                                                           this.dataLoaded.bind(this),
                                                           this.dataLoadedError.bind(this),
                                                           this.globals,
                                                           this.trans,
                                                           this.dialog,
                                                           this.authService
                                                          );

            // it's necessary to check the saved report state to be able to automatically restore the grid to the state
            // which was valid before opening a detail
            let state = this.reportGridHandler.loadGridState();
            if (state) {
                // the last state exists, use it
                savedReport = new SavedReport();
                savedReport.content = JSON.stringify(state.reportContent);
                this.savedReport = savedReport;
            }

            this.reportGridHandler.onGridBuild = () => {
                if (this.savedReport) {
                    let content = <SavedReportContent>JSON.parse( this.savedReport.content);
                    if (this.report.reportType !== ReportType.Public) {
                        // respect the original columns order
                        let res = [];
                        this.report.reportColumns.forEach( c => {
                            let stateC = content.columnsState.find( cc => cc.colId === c.uniqueColumnName );
                            if (stateC) {
                                stateC.hide = false;
                                res.push( stateC );
                            } else {
                                res.push(
                                    {
                                        colId: c.uniqueColumnName,
                                        hide: false,
                                        aggFunc: null,
                                        width: 100,
                                        pivotIndex: null,
                                        pinned: null,
                                        rowGroupIndex: null
                                    }
                                );
                            }
                        });
                        content.columnsState = res;
                    }

                    // old invalid content fix

                    try {
                        this.reportGridHandler.restoreSavedReportContent(content);    
                    } catch (error) {
                        // cancel bad saved model
                        // Confirmed w/ JSO
                        // for now can be ignored
                        console.error(error);
                    }
                    
                    this.report.fulltextSearch = content.fulltextFilter;
                }
            };

            this.reportGridHandler.onInitialized = () => {
                // the reprot is run automatically from the onElasticSearchInitialized event (when the ES is used)
                // or from here
                if (this.report.advancedFilterPurpose === 0 || !this.onInitializeReportRun) {
                    this.onInitializeReportRun = true;
                    this.runReport(true);
                }
                if (gridInitializedCallback) {
                    gridInitializedCallback();
                }
            };

            this.gridOptions = this.reportGridHandler.gridOptions;
            this.gridOptions.onSelectionChanged = () => {
                if (this.onSelectedRowsChanged) {
                    this.onSelectedRowsChanged.emit(this.getSelectedRowIDs());
                }
            };

            // fill default values and setup codelists
            this.report.reportFilters.forEach( f => {
                if (f.editorType === FilterEditorType.codeList) {
                    const keys = this.getCodeListKeysForFilter(f.codeListID);
                    f.codeListElements = [];
                    keys.forEach( k => {
                        f.codeListElements.push( {
                            key: k,
                            value: this.gridSetup.getCodeListRefData(f.codeListID)[k]
                        });
                    });
                }

                switch (f.editorType) {
                    case FilterEditorType.dateRange:
                        let period = Number(f.defaultFilterValue1);
                        if (period) {
                            f.dateRange = new DateRange();
                            f.dateRange.period = period;
                            f.dateRange.initialzeFor(period);
                            this.setFilterDateRangeValues(f);

                        }
                        else if (!f.dateRange) {
                            f.dateRange = new DateRange();
                            f.dateRange.initialzeFor( DateRangeEnum.last_weeks );
                            this.setFilterDateRangeValues( f );
                        }
                        break;
                
                    case FilterEditorType.bit:
                        f.value1 = f.defaultFilterValue1 === "1" || f.defaultFilterValue1 === "true";
                        break;

                    default:
                        f.value1 = f.defaultFilterValue1;
                        f.value2 = f.defaultFilterValue2;
                        break;
                }
            });
            // fill saved report values
            if (this.savedReport) {
                let content = <SavedReportContent>JSON.parse( this.savedReport.content);
                Object.keys(content.filterValues).forEach( k => {
                    let filter = this.report.reportFilters.find(f => {
                        const name = f.queryableColumn ? f.queryableColumn.sqlName : f.queryableParam.name;
                        return (name === k);
                    });
                    if (filter) {
                        let val = content.filterValues[k];
                        if ((filter.editorType === FilterEditorType.dateRange) && val) {
                            // the value is just serialized structure and must be converted to correct date range
                            let dr = new DateRange();
                            dr.initializeFromRange( val );
                            filter.dateRange = dr;
                            this.setFilterDateRangeValues( filter );
                        } else if (filter.editorType === FilterEditorType.bit)  {
                            filter.value1 = val.value1 === "1" || val.value1 === "true";
                        }
                        else {
                            filter.value1 = val.value1;
                            filter.value2 = val.value2;
                        }
                    }
                });
            }
            if (this.onReportFiltersSetup) {
                this.onReportFiltersSetup.emit(this);
            }
            this.reportLoaded = true;
            this.showInitChooseFilterMessage = !this.reportGridHandler.canRun();
            this.changeRef.detectChanges();
            if (reportLoadedCallback) {
                reportLoadedCallback(this.report);
            }
        },
        () => {
            cloakRef.close();
        });
    }

    public runReport( silent = false, forceServerSideRowCount = false ) {
        if (!this.reportGridHandler || !this.reportGridHandler.canRun()) {
            if (!silent) {
                this.dialog.open(InfoDialogComponent, {
                    data: this.trans.instant('admin.web.rep.allPleaseFillAllRequiredFilters')
                });
            }
            return;
        }
        this.ensureBulkTable( () => {
            this.showInitChooseFilterMessage = false;
            this.dataLoadedErrorMessage = '';
            // pass the fulltext only when it's not connected with elastic
            // when working with elastic, the fulltext filer is processed on the elasit side
            this.reportGridHandler.runReport(forceServerSideRowCount);
        });
        // save the last report state each run to ensure the user will find out the report as it has been last time
        this.saveReportState();
    }

    public getCodeListKeysForFilter(codeListID: number) {
        let options = [];
        let map = this.gridSetup.codeListMap[codeListID];
        Object.keys(map.values).forEach(k => {
            options.push(k);
        });

        options = options.sort( (a, b) => {
            let ta = this.trans.instant(map.values[a]);
            let tb = this.trans.instant(map.values[b]);
            return ta.localeCompare(tb);
        });
        options.unshift(null); // for the "nothing selected"
        return options;
    }

    public updatePagination() {
        this.reportGridHandler.updatePagination(this.paginationPageSize);
    }

    public getSelectedRowIDs(): any[] {
        if (!this.reportGridHandler || !this.reportGridHandler.gridApi) {
            return [];
        }
        return this.reportGridHandler.getSelectedRowIDs();
    }

    public getAllRowIDs(): any[] {
        if (!this.reportGridHandler || !this.reportGridHandler.gridApi) {
            return [];
        }
        return this.reportGridHandler.getAllRowIDs();
    }

    public createIDsTempTable(): Observable<ReportResult> {
        return this.reportGridHandler.createIDsTempTable();
    }

    public saveReportState() {
        if (this.report && this.reportGridHandler) {
            this.saveReport( LastSavedReportPefix + this.report.name );
        }
    }

    public destroyTempTable() {
        if (this.currentBulkTable) {
            this.reportService.dropBulkTable( this.currentBulkTable.tableName ).subscribe( r => {});
        }
    }

    public onSaveReportClick() {
        let name = '';
        if (this.savedReport) {
            name = this.savedReport.name;
        } else {
            // predefined the name based on the filter values
            this.report.reportFilters.forEach( f => {
                if (f.value1) {
                    if (name.length > 0) {
                        name += ', ';
                    }
                    name += f.caption + ': ' + f.value1;
                    if (f.value2) {
                        name += ' - ' + f.value2;
                    }
                }
            });
        }
        if (name.length === 0) {
            name = this.trans.instant('admin.web.rep.savedReport') + ' ' + this.report.name;
        }
        let inputContent = <InputDialogContent> {
            message: 'admin.web.save',
            inputName: 'admin.web.nameOf',
            inputContent: name
        };
        let dlg = this.dialog.open(InputDialogComponent, { data: inputContent });
        dlg.afterClosed().subscribe((result) => {
            if (!!result) {
                this.saveReport( inputContent.inputContent );
            }
        });
    }

    private saveReport( name: string ) {
        let content = this.reportGridHandler.createSavedReportContent();
        let sr = <SavedReport>{
            reportID: this.report.reportID,
            name: name,
            userName: sessionStorage.getItem('loggedUserName'),
            content: JSON.stringify(content)
        };
        this.savedReport = sr;
        this.reportService.saveReportState( sr ).subscribe( () => {
            if (this.onReportSaved) {
                this.onReportSaved.emit( sr );
            }
        });
    }

    public deleteAutoSaveReport(callback = null) {
        if (!this.savedReport || !this.savedReport.name.startsWith( LastSavedReportPefix )) {
            return;
        }
        if (this.reportGridHandler) {
            this.reportGridHandler.deleteGridState();
        }
        this.reportService.deleteSavedReport( this.savedReport ).subscribe( () => {
            this.savedReport = null;
            if (callback) {
                callback();
            }
        });
    }

    public onDeleteSavedReportClick() {
        if (this.savedReport) {
            let dlg = this.dialog.open(DeleteDialogComponent, {});
            dlg.afterClosed().subscribe( (result) => {
                if (result) {
                    this.reportService.deleteSavedReport( this.savedReport ).subscribe( () => {
                        if (this.onSavedReportDeleted) {
                            this.onSavedReportDeleted.emit( this.savedReport );
                        }
                        this.savedReport = null;
                    });
                }
            });
        }
    }

    public deleteSavedReportAndReload() {
        this.deleteAutoSaveReport( () => {
            if (this.report) {
                this.reportLoaded = false;
                this.changeRef.detectChanges();
                // save the state
                this.saveReportState();
                // and destroy the potential temp table
                this.destroyTempTable();
                this.loadReport( null, null, null, () => {
                    this.runReport(true, true);
                });
            }
        });
    }

    public refreshCells() {
        this.reportGridHandler.gridApi.refreshCells();
    }

    private fillDefaultVariables() {
        if (!this.variables) {
            this.variables = {};
        }
        if (!this.variables[ReportVariables.languageISOCode]) {
            this.variables[ReportVariables.languageISOCode] = this.globals.getLanguage();
        }
    }

    private dataLoaded() {
        this.dataLoadedErrorMessage = '';
        this.currentRecordCount = this.reportGridHandler.currentRecordCount;
        this.changeRef.detectChanges();
    }

    private dataLoadedError(error) {
        this.currentRecordCount = 0;
        this.dataLoadedErrorMessage = this.trans.instant('admin.web.error') + ': ' + error;
        this.changeRef.detectChanges();
    }

    // the performElasticSearch is called from both, the filter dialog as well as from the fulltext search
    // when the fulltext search is defined
    public performElasticSearch(event: ElasticFilterRequest) {
        console.log('performing elastic search');
        console.log(event);

        this.currentFilterRequest = event;
        this.runReport();
    }

    public onElasticSearchInitialized(event: ElasticFilterRequest) {
        console.log('elastic initialized');
        console.log(event);

        this.currentFilterRequest = event;
        if (this.reportGridHandler.canRun() && !this.onInitializeReportRun) {
            this.onInitializeReportRun = true;
            this.runReport(true);
        }
    }

    public onProductSelected($event) {
        let product = <ProductListItem>$event;
        this.visibleFilters().forEach( f => {
            if (f.editorType === FilterEditorType.productSuggester) {
                f.value1 = product.productId;
            }
        });
        this.runReport();
    }

    public onUserSelected($event) {
        let user = <UserListItem>$event;
        this.visibleFilters().forEach( f => {
            if (f.editorType === FilterEditorType.userSuggester) {
                f.value1 = user.userId;
            }
        });
        this.runReport();
    }

    public onUserJobSelected($event) {
        let user = <Honeycomb.Tenant.Contact.IService.SimpleUserJob>$event;
        this.visibleFilters().forEach( f => {
            if (f.editorType === FilterEditorType.userJobSuggester) {
                f.value1 = user.user.id;
            }
        });
        this.runReport();
    }

    public onPrintClick() {

        let printContent = <PrintDialogContent> {
           reportContext : this.reportGridHandler.dataSource.createPrintPreviewReportContext()
        };
        let printDialog = this.dialog.open(PrintDialogComponent, { data: printContent });
        printDialog.afterClosed().subscribe((result) => {
            if (!!result) {
                let cloakRef = this.dialog.open(SavingDialogComponent);
                let context = this.reportGridHandler.dataSource.createPrintReportContext();
                context.pagination.rowCount = this.paginationPageSize;
                // Transfer preview print settings to production print settings
                context.printSettings = printContent.reportContext.printSettings;
                this.reportService.printReport(context).subscribe( r => {
                    cloakRef.close();
                    let url = this.reportService.getUrlPrefix() + 'downloadPrinted/' + r.token + '?TenantID=' + this.globals.getTenantID();
                    location.href = url;
                }, e => {
                    cloakRef.close();
                });
            }
        });
    }

    // the bulk table, created after the elastic search is used only when a concrete filter purpose (e.g. Customer or Product is set)
    private ensureBulkTable( ready: Function ) {

        var dateRange = this.report.reportFilters.find(rf => rf.editorType === FilterEditorType.dateRange);
        if(!!dateRange && !!dateRange.value2) {
            // DateTo must end at the end of the day
            dateRange.value2 = this.datePipe.transform(new Date(Date.parse(dateRange.value2)).setHours(23, 59, 59, 999), 'yyyy-MM-ddThh:mm:ss.SSS')
        }

        if (!this.filterPurpose) {
            ready();
            return;
        }
        if (this.lastFulltextSearch === this.report.fulltextSearch && this.lastFilterRequest === this.currentFilterRequest) {
            ready();
            return;
        }
        if (this.currentBulkTable) {
            this.reportService.dropBulkTable( this.currentBulkTable.tableName ).subscribe( r => {});
        }
        this.currentBulkTable = null;
        this.setupDataSourceFilterTables();
        this.lastFulltextSearch = this.report.fulltextSearch;
        this.lastFilterRequest = this.currentFilterRequest;
        let request = this.currentFilterRequest;
        if (this.report.fulltextSearch) {
            if (!request) {
                // called from the fulltext search
                request = new ElasticFilterRequest('');
                request.filterPurpose = this.filterPurpose;
            }
        }
        if (!request) {
            ready();
            return;
        }
        request.searchString = this.report.fulltextSearch;
        let cloakRef = this.dialog.open(SavingDialogComponent);

        console.log('bulk table required');
        console.log(request);
        this.reportService.createBulkTable( request ).subscribe( r => {
            this.currentBulkTable = r;
            this.setupDataSourceFilterTables();
            cloakRef.close();
            console.log('bulk table ready');
            console.log(this.currentBulkTable);
            ready();
        }, () => {
            cloakRef.close();
        });
    }

    private setupDataSourceFilterTables() {
        this.reportGridHandler.dataSource.filterTempTables = [];
        if (this.currentBulkTable) {
            this.reportGridHandler.dataSource.filterTempTables.push(this.currentBulkTable);
        }
        if (this.fixedFilterTable) {
            this.reportGridHandler.dataSource.filterTempTables.push(this.fixedFilterTable);
        }
    }

    private onChkChange(change: MatCheckboxChange, filter: any) {
        filter.value1 = !!change.checked;
    }

    public onFilterToggleClick() {
        (<HTMLButtonElement>document.querySelector('.ag-side-buttons button')).click();
    }
}
