import { RouterModule,
         Routes,
         PreloadAllModules }                    from '@angular/router';
import { HomeComponent }                        from './home/home.component';
import { LoginComponent }                       from './login/login.component';
import { AuthGuard }                            from './common/auth.guard';

import { NgModule } from '@angular/core';

export function requireAdmin() {
    return {
        Only: ['admin'],
        RedirectTo: 'login'
    };
}

export function requireSysAdmin() {
    return {
        Only: ['sysadmin'],
        RedirectTo: 'login'
    };
}

export function requireUser() {
    return {
        Only: ['user'],
        RedirectTo: 'login'
    };
}

// tslint:disable:max-line-length
const appRoutes: Routes = [
    { path: 'setting-core', loadChildren: './setting-core/setting-core.module#SettingCoreModule' },
    { path: 'setting', loadChildren: './setting/setting.module#SettingModule' },
    { path: 'user', loadChildren: './user/user.module#UserModule' },
    { path: 'statistics', loadChildren: './statistics/statistics.module#StatisticsModule' },
    { path: 'communication', loadChildren: './communication/communication.module#CommunicationModule' },
    { path: 'survey', loadChildren: './survey/survey.module#SurveyModule' },
    { path: 'product', loadChildren: './product/product.module#ProductModule' },
    { path: 'planner', loadChildren: './planner/planner.module#PlannerModule' },
    { path: 'tasker', loadChildren: './tasker/tasker.module#TaskerModule' },
    { path: 'order', loadChildren: './order/order.module#OrderModule' },
    { path: 'login', component: LoginComponent},
    { path: '', component: HomeComponent, canActivate: [AuthGuard], data: { locKey: 'admin.web.home' }}, // Keep this at 0 index, this is default Home
  ];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: false, preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

// const routes: Routes = appRoutes;

// export const routing = RouterModule.forRoot(routes, { enableTracing: true, preloadingStrategy: NoPreloading });
