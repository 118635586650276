
import { DatePipe } from '@angular/common';
import { Report } from '../model/report.model';
import { ReportService } from '../../../shared/report-service';
import { ReportContext } from '../model/report-context.model';
import { ReportResult } from '../model/report-result.model';
import { ReportSorting } from '../model/report-sorting.model';
import { ColumnFilter } from '../model/column-filter.model';
import { ColumnFilterComparision } from '../model/column-filter-comparision.enum';
import { ReportDataModel } from '../model/report-data-model.enum';
import { BulkInsertTableResult } from '../model/bulk-insert-table-result.model';
import { Observable, of } from 'rxjs';
import { ReportGridComponent } from '../report-grid.component';
import { isNullOrUndefined } from '../../functions';
import { FilterEditorType } from '../model/filter-editor-type.enum';
import { IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-enterprise/all-modules';
import { catchError } from 'rxjs/operators';

export class GridDataSource implements IServerSideDatasource {

    public rowCount;

    constructor(private component: ReportGridComponent,
                private report: Report,
                private pageSize: number,
                private dataLoadedCallback: Function,
                private getAllDataErrorCallback: Function) {
    }

    public lastQueryPattern: string;
    public lastKnownTotalCount: number;

    // the fixed filter data optional filter is a hashmap, where the key is QueryablColumnID and the value is the filtered value
    public fixedFilterData = {};

    public lastData: any[];

    public lookup: { [key: number]: string };
    public parsedStyles = {};

    public filterTempTables: BulkInsertTableResult[];

    // used for the client side model
    public getAllData(getAllDataCallback: Function) {
        let context = this.createContext(null);
        this.runReport(context, null, getAllDataCallback);
    }

    // server side datasource api function
    public getRows(params: IServerSideGetRowsParams) {
        let context = this.createContext(params);
        this.runReport(context, params);
    }

    public createIDsTempTable(): Observable<ReportResult> {
        let context = this.createContext(null);
        context.resultToTempTable = true;
        context.pagination.rowCount = 10000000;
        context.pagination.offset = 0;
        let col = this.report.reportColumns.find( c => c.rowIDColumn );
        if (!col) {
            return;
        }
        context.resultColumnIDName = col.columnName;
        return this.component.reportService.runReport(context);
    }

    public createPrintReportContext(): ReportContext {
        let context = this.createContext(null);
        context.pagination.rowCount = 10000000;
        context.pagination.offset = 0;
        let columnsState = this.component.reportGridHandler.columnApi.getColumnState();
        columnsState.filter( c => c.rowGroupIndex != null).sort( (a, b) => a.rowGroupIndex - b.rowGroupIndex).forEach(c => {
            context.groupByColumns.push(c.colId);
        });
        return context;
    }

    public createPrintPreviewReportContext(): ReportContext {
        let context = this.createContext(null);
        context.pagination.offset = 0;
        context.pagination.rowCount = 100;
        context.printSettings.isPreview = true;
        let columnsState = this.component.reportGridHandler.columnApi.getColumnState();
        columnsState.filter( c => c.rowGroupIndex != null).sort( (a, b) => a.rowGroupIndex - b.rowGroupIndex).forEach(c => {
            context.groupByColumns.push(c.colId);
        });
        return context;
    }

    private async runReport(context: ReportContext,
                      params: IServerSideGetRowsParams = null,
                      getAllDataCallback: Function = null) {



        let err = '';
        let r = await this.component.reportService.runReport(context)
                            .pipe(catchError(e => {
                                err = e;
                                return of({data: null, totalCount: -1, lookup: null });
                            }))
                            .toPromise();

        if (r.data === null) {
            this.lastData = null;
            if (params) {
                params.fail();
            }
            if (this.getAllDataErrorCallback) {
                this.getAllDataErrorCallback(err);
            }
        }

        
        if (params) {
            this.lastQueryPattern = this.getQueryPattern( params );
        }
        console.log('data source, report run');
        this.lookup = r.lookup;
        let rows = [];
        r.data.forEach(row => {
            let obj = {};
            this.report.reportColumns.forEach((e, i) => {
                // handle difference between simple value and cell object model
                if (row[i] && typeof(row[i]) === 'object' && ('v' in row[i])) {
                    obj[e.uniqueColumnName] = row[i].v;
                    if (row[i].c) {
                        obj[e.uniqueColumnName + '_c'] = row[i].c;
                    }
                    if (row[i].s) {
                        obj[e.uniqueColumnName + '_s'] = row[i].s;
                    }
                } else {
                    obj[e.uniqueColumnName] = row[i];
                }
            });
            if (context.groupCountRequired) {
                obj['_count'] = row[row.length - 1]; // count is always the last column
            }
            rows.push(obj);
        });
        if (context.needTotalCount || this.report.reportDataModel === ReportDataModel.clientSide) {
            this.lastKnownTotalCount = r.totalCount;
        }
        this.lastData = rows;
        if (params) {
            // params.success({ rowData: rows, rowCount: this.lastKnownTotalCount });// rows, this.lastKnownTotalCount);
            params.success({ rowData: rows, rowCount: this.lastKnownTotalCount });
        }
        if (getAllDataCallback) {
            getAllDataCallback(rows);
        }
        if (this.dataLoadedCallback) {
            this.dataLoadedCallback();
        }
/*
        this.component.reportService.runReport(context).subscribe(r => {
            if (params) {
                this.lastQueryPattern = this.getQueryPattern( params );
            }
            console.log('data source, report run');
            this.lookup = r.lookup;
            let rows = [];
            r.data.forEach(row => {
                let obj = {};
                this.report.reportColumns.forEach((e, i) => {
                    // handle difference between simple value and cell object model
                    if (row[i] && typeof(row[i]) === 'object' && ('v' in row[i])) {
                        obj[e.uniqueColumnName] = row[i].v;
                        if (row[i].c) {
                            obj[e.uniqueColumnName + '_c'] = row[i].c;
                        }
                        if (row[i].s) {
                            obj[e.uniqueColumnName + '_s'] = row[i].s;
                        }
                    } else {
                        obj[e.uniqueColumnName] = row[i];
                    }
                });
                if (context.groupCountRequired) {
                    obj['_count'] = row[row.length - 1]; // count is always the last column
                }
                rows.push(obj);
            });
            if (context.needTotalCount || this.report.reportDataModel === ReportDataModel.clientSide) {
                this.lastKnownTotalCount = r.totalCount;
            }
            this.lastData = rows;
            if (params) {
                // params.success({ rowData: rows, rowCount: this.lastKnownTotalCount });// rows, this.lastKnownTotalCount);
                params.success({ rowData: rows });
            }
            if (getAllDataCallback) {
                getAllDataCallback(rows);
            }
            if (this.dataLoadedCallback) {
                this.dataLoadedCallback();
            }
        },
        err => {
            this.lastData = null;
            if (params) {
                params.fail();
            }
            if (this.getAllDataErrorCallback) {
                this.getAllDataErrorCallback(err);
            }
        });
        */
    }

    private getQueryPattern(params: IServerSideGetRowsParams): string {
        return JSON.stringify(
        {
            grouping: params.request.rowGroupCols,
            keys: params.request.groupKeys,
            filtering: params.request.filterModel,
            reportFiltes: this.report.reportFilters,
            filterTempTables: this.filterTempTables,
            fulltextFilter: this.report.fulltextSearch
        });
    }

    private createContext(params: IServerSideGetRowsParams): ReportContext {
        let context = new ReportContext();

        context.reportID = this.report.reportID;
        context.filterTempTables = this.filterTempTables;
        context.variables = this.component.variables;
        context.commandTimeout = this.report.commandTimeout;
        if (this.report.reportDataModel === ReportDataModel.clientSide && this.report.maxRecordCount > 0) {
            context.maxRecordCount = this.report.maxRecordCount;
        }
        if (params && params.request) {
            context.pagination.offset = isNullOrUndefined(params.request.startRow) ? 0 : params.request.startRow;
            context.pagination.rowCount = params.request.endRow - params.request.startRow;
            context.sorting = this.GetSorting(params);

            // the total count query is an expensive operation, so query the total
            // count only when the count-affecting condisions are changed
            let queryPattern = this.getQueryPattern( params );
            context.needTotalCount = (this.lastQueryPattern !== queryPattern);
            if (context.needTotalCount) {
                console.log('The query pattern has changed, total count is needed');
            }

            context.groupCountRequired = false;
            if (params.request.rowGroupCols) {
                params.request.rowGroupCols.forEach(g => {
                    context.groupByColumns.push(g.field);
                    context.groupCountRequired = true;
                });
            }
            if (params.request.groupKeys) {
                params.request.groupKeys.forEach(k => {
                    context.groupByValuesFilter.push(k);
                });
            }
            if (params.request.filterModel) {
                Object.keys(params.request.filterModel).forEach(k => {
                    const filterModel = params.request.filterModel[k];
                    let colFilter = <ColumnFilter>{
                        columnName: k,
                        comparision: ColumnFilterComparision[<string>filterModel.type],
                    };
                    switch (filterModel.filterType) {
                        case 'date':
                            colFilter.value1 = filterModel.dateFrom;
                            colFilter.value2 = filterModel.dateTo;
                            break;

                        default:
                            colFilter.value1 = filterModel.filter;
                            colFilter.value2 = filterModel.filterTo;
                            break;
                    }
                    context.columnFilters.push(colFilter);
                });
            }
        }
        this.report.reportFilters.forEach(f => {
            let val1: any;
            if (f.editorType === FilterEditorType.bit) { // bit can be false
                val1 = (f.value1 === '1' || f.value1 === 'true' || f.value1 === true);
            }
            else {
                val1 = (f.value1 ? f.value1 : f.defaultFilterValue1)
            }
            
            let val2 = (f.value2 ? f.value2 : f.defaultFilterValue2);
            if (f.editorType === FilterEditorType.dateRange && !f.value1) {
                //undefined date range filter, ignore default values and pass null
                val1 = null;
                val2 = null;
            }
            
            if (val1 || val2  || f.editorType === FilterEditorType.bit) {

                let colFilter = <ColumnFilter>{
                    queryableColumnID: f.queryableColumnID,
                    queryableParamID: f.queryableParameterID,
                    comparision: f.comparision,
                    value1: this.formatValue(val1),
                    value2: this.formatValue(val2, true)
                };
                context.columnFilters.push(colFilter);
            }
        });
        if (this.fixedFilterData) {
            Object.keys( this.fixedFilterData ).forEach( k => {
                let colFilter = <ColumnFilter>{
                    queryableColumnID: Number(k),
                    comparision: ColumnFilterComparision.equals,
                    value1: this.fixedFilterData[k]
                };
                context.columnFilters.push(colFilter);
            });
        }
        // when the advanced filer purpose is not set, but the fulltext is turned on
        // use the internal report full text logic
        if (!this.report.advancedFilterPurpose && this.report.fulltextSearch) {
            context.fulltextFilter = this.report.fulltextSearch;
        }
        return context;
    }

    private GetSorting(params: IServerSideGetRowsParams): ReportSorting[] {
        let sorts: ReportSorting[] = [];
        params.request.sortModel.forEach(s => {
            let sort = new ReportSorting();
            sort.sortDir = s.sort;
            sort.sortField = s.colId;
            sorts.push(sort);
        });
        return sorts;
    }

    private formatValue(input: any, isSecondRangeValue = false): string {

        if (typeof(input) === 'boolean') {
            return input ? '1' : '0';
        }

        if (!input) {
            return '';
        }
        if (input instanceof Date) {
            let res = this.component.datePipe.transform(<Date>input, 'yyyy-MM-dd');
            if (isSecondRangeValue) {
                res += 'T23:59:59'; // till date value, always include the whole day
            }
            return res;
        }
        return input.toString();
    }

    public getClass( row: any, col: string, origClass ) {
        if (!row) {
            return null;
        }
        if (row[col + '_c']) {
            return origClass + ',' + this.lookup[row[col + '_c']];
        }
        return origClass;
    }

    public getStyle( row: any, col: string ) {
        if (!row) {
            return null;
        }
        if (row[col + '_s']) {
            let lkey = row[col + '_s'];
            if (this.parsedStyles[lkey]) {
                return this.parsedStyles[lkey];
            }
            let style = this.parseCSSText(this.lookup[lkey]).style;
            this.parsedStyles[lkey] = style;
            return style;
        }
        return null;
    }

    public parseCSSText(cssText) {
        let cssTxt = cssText.replace(/\/\*(.|\s)*?\*\//g, ' ').replace(/\s+/g, ' ');
        let style = {}, [, ruleName, rule] = cssTxt.match(/ ?(.*?) ?{([^}]*)}/) || [, , cssTxt];
        let cssToJs = s => s.replace(/\W+\w/g, match => match.slice(-1).toUpperCase());
        let properties = rule.split(';').map(o => o.split(':').map(x => x && x.trim()));
        for (let [property, value] of properties) {
            style[cssToJs(property)] = value;
        }
        return {cssText, ruleName, style};
    }

}
