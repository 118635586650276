import { Component, ChangeDetectorRef, Inject } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TranslationService } from 'src/app/shared/translation.service';
import { Honeycomb } from 'src/app/shared/honeycomb-api/honeycomb-api';
import { Globals } from '../globals';

@Component({
    selector: 'hc-multilang-input-renderer',
    template:
    `
        <div>{{ text | trans }}</div>
    `,
    // <hc-multilang-input style="display: block; width: 100%; min-height: 60px; height: 60px" #multilang [(ngModel)]="text" [locKeyPrefix]="locKeyPrefix" [entityID]="entityID"></hc-multilang-input>
})
export class MultilangInputRendererComponent implements ICellRendererAngularComp {
    public displayValue: any;
    public value: any;
    public text: any;
    public locKeyPrefix: string;
    public entityID: any;

    constructor(
        private cd: ChangeDetectorRef,
        private globals: Globals,
        // tslint:disable-next-line: max-line-length
        @Inject('TenantLocalizationController') private tenantLocController: Honeycomb.Tenant.LookupTables.IService.TenantLocalizationController,
        public trans: TranslationService){
    }

    async agInit(params: any) {
        this.locKeyPrefix = params.colDef.refData.locKeyPrefix;
        if (!params.data[params.colDef.field]) {
            this.text = '';
            return;
        } else if (!params.data[params.colDef.field].langs) {
            let locResult = await this.tenantLocController.Get(this.globals.getLanguage(), encodeURI(params.data[params.colDef.field]))
                                      .toPromise();
            if (locResult) {
                this.text = locResult.value;
            } else {
                this.text = params.data[params.colDef.field];
            }
        }

        if (params.data[params.colDef.field].langs && params.data[params.colDef.field].langs.length > 0) {
            this.text = params.data[params.colDef.field].langs[0].text;
        } else if (!!params.data[params.colDef.field].pureText) {
            this.text = params.data[params.colDef.field].pureText;
        }

        this.cd.detectChanges();
    }

    isPopup(): boolean {
        return false;
      }

    refresh(params: any): boolean {

        this.locKeyPrefix = params.colDef.refData.locKeyPrefix;

        let paramVals = params.getValue();
        if (!!paramVals) {
            this.text = paramVals;
        }
        if (!!paramVals && !!paramVals.pureText) {
            this.text = paramVals.pureText;
        }
        if (!!paramVals && paramVals.langs && paramVals.langs.length > 0) {
            paramVals.langs.forEach(l => {
                if (!!l.text && l.text.startsWith(this.locKeyPrefix)) {
                this.tenantLocController.Get(this.globals.getLanguage(), encodeURI(params.data[params.colDef.field])).subscribe(ll => {
                    if (ll) {
                        this.text = ll.value;
                        this.cd.detectChanges();
                    }
                });
                }
            });
        }

        if (!!paramVals && paramVals.langs && paramVals.langs.length > 0) {
            this.text = paramVals.langs[0].text;
        }
        if (!!paramVals && !paramVals.langs) {
            this.tenantLocController.Get(this.globals.getLanguage(), encodeURI(params.data[params.colDef.field])).subscribe(l => {
                if (l) {
                    this.text = l.value;
                    this.cd.detectChanges();
                }
            });
        }

        this.entityID = params.data.backgroundID;

        params.refreshCell();
        this.cd.detectChanges();
        return true;
    }
}
