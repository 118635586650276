import { DataTypeEnum } from '../common/data-type.enum';

export class InterfaceModel {
    public interfaceID: number;
    public name: string;
    public description: string;
    public agenda: PortalAgenda;
    public interfaceType: InterfaceType;
    public storeExported: boolean;

    public customQuery: string;
    public dialogID: number;
    public dialog: DialogViewModel;
    public exportSelector: ExportContentSelector;

    public lastExportedDate: Date;
    public lastExportedRowVersion: number;
    public interfaceFiles: Array<InterfaceFile>;

    // Assembly info
    public controllerType: string;
    public serviceType: string;
    public assemblyName: string;
}

export enum PortalAgenda {
    Undefined = 0,
    Product = 1,
    Contact = 2,
    OrdersOverview = 3,
    ProductCategory = 4,
    VouchersOverview = 5,
    PromotionsOverview = 6,
    TenantLocalizations = 7,
    TagEmissions = 8,
    GlobalLocalizations = 9,
    VoucherTemplatesList = 10,
    StorageList = 11,
    Attendance = 12
}

export enum ImportExportFileFormat {
    CSV = 1,
    XLSX = 2,
    ZIP = 3,
    XML = 4
}

export enum InterfaceType {
    Undefined = 0,
    Import = 1,
    Export = 2
}

export enum ExportContentSelector {
    Undefined     = 0,
    AllUnexported = 1,
    SinceTillDate = 2,
    All           = 4,
    AllSelected   = 8
}

export class InterfaceFile {
    public interfaceFileID: number;
    public interfaceID: number;
    public type: ImportExportFileFormat;
    public name: string;
    public extension: string;

    // mapped from interface model
    public exportSelector: ExportContentSelector;
}

export class DialogViewModel {
    public dialogID: number;
    public name: string;
    public caption: string;
    public fields: Array<DialogFieldViewModel>;
}

export class DialogFieldViewModel {
    public dialogFieldID: number;
    public dialogID: number;

    public name: string;
    public caption: string;
    public dataType: DataTypeEnum;
    public codeListID: number;
    public regExValidator: string;
    public minValue: number;
    public maxValue: number;
    public required: boolean;
    public displayOrder: number;

    public enumItems: Array<DialogFieldEnumItem>;
}

export class DialogFieldEnumItem {
    public dataValue: string;
    public displayValue: string;
    public displayOrder: number;
}
