import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ProductAttributeTypeWithValue } from '../product.model';
import { DataTypeEnum } from '../data-type.enum';
import { CodeLookupCacheService } from 'src/app/shared/code-lookup-cache-service';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';

@Component({
    selector: 'hc-typed-cell',
    templateUrl: 'grid-typed-renderer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridTypedRendererComponent {
    private params: ProductAttributeTypeWithValue;

    public dataTypes = DataTypeEnum;
    public dataType: DataTypeEnum = DataTypeEnum.DateRange;
    public valueStr: string = null;
    public valueObj: any = null;

    private elem: HTMLElement;
    constructor(private cd: ChangeDetectorRef,
        private codeCacheService: CodeLookupCacheService) {
            this.codeCacheService.cacheReady.subscribe(() => {
                this.refreshData();
                this.cd.detectChanges();
            });
    }

    agInit(params: ICellRendererParams): void {
        this.params = params.data as ProductAttributeTypeWithValue;
        this.refreshData();
    }

    refresh(params: ICellRendererParams): boolean {
        this.params = params.data as ProductAttributeTypeWithValue;
        this.refreshData();
        this.cd.detectChanges();
        return true;
    }

    refreshData() {
        this.dataType = this.params.dataType;
        this.valueStr = this.params.value;

        if (this.dataType === DataTypeEnum.DateRange) {
            try {
                let val = JSON.parse(this.params.value);
                let valObj = {
                    Since: new Date(val.Since),
                    Till: new Date(val.Till)
                };
                this.valueObj = valObj;

            } catch {
                this.valueObj = null;
            }
        }
        if (this.dataType === DataTypeEnum.NumberRange) {
            try {
                let val = JSON.parse(this.params.value);
                let valObj = {
                    From: Number(val.From),
                    To: Number(val.To)
                };
                this.valueObj = valObj;
            } catch {
                this.valueObj = null;
            }
        }

        if (this.dataType === DataTypeEnum.Dimensions) {
            try {
                let val = JSON.parse(this.params.value);
                let valObj = {
                    DepthCM: Number(val.DepthCM),
                    HeightCM: Number(val.HeightCM),
                    WidthCM: Number(val.WidthCM)
                };
                this.valueObj = valObj;
            } catch {
                this.valueObj = {
                    DepthCM: null,
                    HeightCM: null,
                    WidthCM: null
                };
            }
        }

        if (this.dataType === DataTypeEnum.DateTime ||
            this.dataType === DataTypeEnum.ShortDate) {
            try {
                this.valueObj = new Date(this.valueStr);
                if (this.valueObj.toString() === 'Invalid Date') {
                    this.valueObj = null;
                }
            } catch {
                this.valueObj = new Date();
            }
        }

        if (this.dataType === DataTypeEnum.Media) {
            try {
                this.valueStr = this.codeCacheService.getMediaDescription(Number(this.params.value));
            } catch {
                this.valueStr = null;
            }
        }

        if (this.dataType === DataTypeEnum.VoucherTemplate) {
            try {
                this.valueStr = this.codeCacheService.getMediaDescriptionForVoucherTemplate(Number(this.params.value));
            } catch {
                this.valueStr = null;
            }
        }

        if (this.dataType === DataTypeEnum.CodeList) {
            try {
                this.valueStr = this.codeCacheService.getCodeListValue( this.params.codeListId, this.params.value);
            } catch {
                this.valueStr = null;
            }
        }
    }
}

