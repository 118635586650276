import { RequestParams } from './assets/request-params.model';
import { HttpHeaders } from './assets/http-headers.interface';
import { RequestParamType } from './assets/request-param-type.enum';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { HttpMethod } from './assets/http-method.enum';
import { Globals } from 'src/app/common/globals';
import { Injectable, Inject } from '@angular/core';
import { KeyValuePair } from '../keyValuePair.model';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiCaller {

    constructor(
        @Inject(HttpClient) private httpClient: HttpClient,
        @Inject(Globals) private globals: Globals) {
    }

    public request(controllerRoute: string,
        requestParams: RequestParams,
        headers?: HttpHeaders): Observable<any> {
        if (!this.httpClient) {
            console.error('Apicaller not initialized');
        }

        const params = this.resolveEndpointParams(requestParams);
        const endpointRoute = this.resolveEndpointRoute(requestParams.endpointRoute, params);
        const authHeader = { Authorization: this.globals.getAuthorizationHeaderValue() } as HttpHeaders;
        const options = { headers: Object.assign({}, headers, authHeader) };

        if (requestParams.method === HttpMethod.GET) {
            return this.httpClient.get<any>([this.globals.getApiURL(), controllerRoute, endpointRoute].join('/'), options);
        }

        if (requestParams.method === HttpMethod.DELETE) {
            return this.httpClient.delete<any>([this.globals.getApiURL(), controllerRoute, endpointRoute].join('/'), options);
        }

        if (requestParams.method === HttpMethod.POST) {
            return this.httpClient.post<any>([this.globals.getApiURL(), controllerRoute, endpointRoute].join('/'), params.BODY, options);
        }

        if (requestParams.method === HttpMethod.PUT) {
            return this.httpClient.put<any>([this.globals.getApiURL(), controllerRoute, endpointRoute].join('/'), params.BODY, options);
        }
    }

    private resolveEndpointParams(requestParams: RequestParams): any {
        const params: any = {};
        requestParams.params.forEach(param => {
            if (!params.hasOwnProperty(param.type)) {
                params[param.type] = {};
            }
            if (param.type === RequestParamType.BODY || param.type === RequestParamType.FORM) {
                params[param.type] = param.value;
            } else {
                params[param.type][param.name] = param.value;
            }
        });
        if (params.hasOwnProperty(RequestParamType.BODY) && params.hasOwnProperty(RequestParamType.FORM)) {
            console.warn('Request contains both body & form params. Using body params only.');
            delete (params[RequestParamType.FORM]);
        }
        return params;
    }

    private resolveEndpointRoute(endpointRoute: string, params: any): string | null {
        if (params.hasOwnProperty(RequestParamType.ROUTE)) {
            for (const name in params[RequestParamType.ROUTE]) {
                const regex = new RegExp(`\{\*?${name}\??\}`, 'gi');
                endpointRoute = endpointRoute.replace(regex, params[RequestParamType.ROUTE][name]);
            }
            if (endpointRoute.match(/[{}]+/)) {
                console.error('Route cannot be resolved');
                return null;
            }
        }
        if (params.hasOwnProperty(RequestParamType.QUERY)) {
            const queryParams = this.paramsToString(params[RequestParamType.QUERY]);
            endpointRoute += queryParams.length ? `?${queryParams}` : '';
        }
        return endpointRoute;
    }

    private paramsToString(pairs: any): string {
        const result: string[] = [];
        for (const key in pairs) {
            if (Array.isArray(pairs[key])) {
                pairs[key].forEach((v: any) => result.push(`${key}=${v}`));
            } else {
                result.push(`${key}=${pairs[key]}`);
            }
        }
        return result.join('&');
    }

    public uploadFile(controllerRoute: string,
                      requestParams: RequestParams
                      ): Observable<any> {

        const params = this.resolveEndpointParams(requestParams);
        let endpointRoute = this.resolveEndpointRoute(requestParams.endpointRoute, params);
        let url = [this.globals.getApiURL(), controllerRoute, endpointRoute].join('/');
        let timeout = 60;
        let files = requestParams.params.find( p => p.name === 'files').value;
        let observable =
            from(new Promise((resolve, reject) => {
                let formData = new FormData();
                let xhr = new XMLHttpRequest();
                for (let i = 0; i < files.length; i++) {
                    formData.append('uploads[]', files[i], files[i].name);
                }

                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            return resolve(xhr.response);
                        } else {
                            return reject(xhr.response);
                        }
                    }
                };
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Authorization', this.globals.getAuthorizationHeaderValue());
                xhr.setRequestHeader('Accept-Language', this.globals.getLanguage());
                xhr.send(formData);
            }));
        return observable;
    }
}
