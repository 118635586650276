import { ISortableFilter }          from '../common/sortable-filter';
import { IBaseService }             from './service.interface';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { HttpClient }               from '@angular/common/http';
import { Globals }                  from '../common/globals';
import { CompanyViewModel }         from '../setting/company/company-view.model';
import { share }                    from '../../../node_modules/rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyService implements IBaseService<any> {

    private _commonService: CommonService;

    private companies: Array<CompanyViewModel> = null;

    private _selectedCompanyID = -1;

    public set setCompanyID(companyID: number) {
        this._selectedCompanyID = companyID;
    }

    public get getCompanyID(): number {
        return this._selectedCompanyID || -1;
    }

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'company');
        this._commonService.getCustomHeaders = () => {
            return {
                'X-Airme-CompanyId': this._selectedCompanyID
            };
        };
    }

    public async loadCompanies() {
        let cps = (await this.getItems().toPromise()) as any;
        this.companies = cps as Array<CompanyViewModel>;
    }

    public async hasMultipleCompanies(): Promise<boolean> {
        if (this.companies == null) {
            await this.loadCompanies();
        }
        return this.companies.length > 1;
    }

    public async getPreloadedCompanies(): Promise<Array<CompanyViewModel>> {
        if (this.companies == null) {
            await this.loadCompanies();
        }
        return this.companies;
    }


    public getItems(): Observable<any> {
        return this._commonService.get('listAll');
    }

    public getActive(): Observable<CompanyViewModel> {
        /*
        if (this.companies == null) {
            await this.loadCompanies();
        }
        return Observable.create(this.companies.find(c => c.companyId === this._selectedCompanyID));*/
        return this._commonService.get('getActive').pipe(share()) as Observable<CompanyViewModel>;
    }

    public searchItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('getCompanies', filter);
    }

    public deleteItem(id: any): Observable<any> {

        return this._commonService.delete(id);
    }
    public async insertCompany(item: any): Promise<any> {
        this.companies = null;
        let res = await this._commonService.post('', item).toPromise();
        await this.loadCompanies();
        return res;
    }

    public async updateCompany(id: any, item: any): Promise<any> {
        this.companies = null;
        let res = await this._commonService.updateItem(id + '', item).toPromise();
        await this.loadCompanies();
        return res;
    }
    public getDetail(id: string): Observable<any> {
        return this._commonService.get(id);
    }

    public updateItem(id: string, item: any): Observable<any> {
        // Use update company instead
        return Observable.create(null);
    }
    public insertItem(item: any): Observable<any> {
        // Use insert company instead
        return Observable.create(null);
    }
}
