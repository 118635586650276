export enum ReportDetailType {
    none = 0,
    product = 1,
    user = 2,
    voucher = 3,
    media = 4,
    transaction = 5,
    report = 6,
    club = 7,
    surveyQuestion = 8,
    survey = 9,
    productSet = 10,
    categoryDetail = 11,
    categoryTreeDetail = 12,
    country = 13,
    currency = 14,
    language = 15,
    localization = 16,
    measurementUnit = 17,
    region = 18,
    vat = 19,
    aggregator = 20,
    codeList = 21,
    productAttributeType = 22,
    company = 23,
    walletTemplate = 24,
    shippingType = 25,
    paymentType = 26,
    paymentProvider = 27,
    setting = 28,
    componentConfiguration = 29,
    skin = 30,
    variant = 31,
    filter = 32,
    slotPlan = 33,
    eventSource = 34,
    location = 35,
    userAttribyteType = 36,
    portalUserDetail = 37,
    discountTypeDetail = 38,
    discountAssignmentDetail = 39,
    segmentDetail = 40,
    backgroundImageDetail = 41,
    columnContent = 42,
    pbiTemplate = 43,
    meaning = 44,
    input = 45,
    activity = 46,
    operation = 47,
    task = 48,
    promotion = 49,
    htmlView = 50,
    categoryAttributeType = 51,
    shippingOptionsProvider = 52,
    shippingPriceProvider = 53,
    voucherTemplates = 54,
    attendanceDetail = 55,
}
