import { Component, OnInit, Input, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PortalAgenda, InterfaceModel, InterfaceType, InterfaceFile } from 'src/app/interface/interface.model';
import { InterfaceService } from 'src/app/shared/interfaces.service';
import { ExportRequestModel } from '../export-request-model.model';
import { ExportDialogComponent } from '../file-export.component';
import { TranslationService } from 'src/app/shared/translation.service';
import { ImportDialogComponent, ImportPromptDialogComponent } from '../file-import.component';
import { FileImportModel } from '../file-import.model';
import { ImportFileMetaData } from '../import-file-metadata.model';
import { Globals } from '../globals';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'hc-interfaces-menu',
  templateUrl: './interfaces-menu.component.html',
  styleUrls: ['./interfaces-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterfacesMenuComponent implements OnInit, AfterViewInit {

  @Input()
  public agenda: PortalAgenda = null;

  @Input()
  public exportCallback: () => {} = null;

  @Input()
  public selectedIDs: Array<any> = [];

  public interfaces: Array<InterfaceModel> = [];

  public hasExports = false;

  public exports: Array<InterfaceModel> = [];

  public hasImports = false;

  public imports: Array<InterfaceModel> = [];

  constructor(
    private interfaceService: InterfaceService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private trans: TranslationService,
    private globals: Globals,
    private cd: ChangeDetectorRef
  ) {
   }

  exportClick(interfaces: InterfaceModel[]) {
    let exportModel = new ExportRequestModel();
    exportModel.agenda = this.agenda;
    exportModel.content.ids = this.selectedIDs;
    exportModel.content.uILanguageIsoCode = this.globals.getLanguage();
    exportModel.exportDownloadUrl = '/api/interface/download/';
    exportModel.exportCallback = (requestModel: ExportRequestModel) => {
      return this.interfaceService.export(requestModel.selectedExporFileID, requestModel);
    };
    exportModel.dialogTitle = 'admin.web.export-products';
    let dialogRef = this.dialog.open(ExportDialogComponent, { data: exportModel } );
  }

  ngOnInit() {
  }

  async ngAfterViewInit() {
    this.interfaces = this.interfaceService.getAgendaInterfaces(this.agenda);
    this.exports = this.interfaces.filter(i => i.interfaceType === InterfaceType.Export);
    this.imports = this.interfaces.filter(i => i.interfaceType === InterfaceType.Import);
    this.hasExports = this.exports.length > 0;
    this.hasImports = this.imports.length > 0;
    this.cd.detectChanges();
  }

  public importClick(selector: string, i: string) {
    document.getElementById(selector + i).getElementsByTagName('button')[0].click();
  }

  public importFile(file: File, interfaceFileID: number) {
    let promptDialog = this.dialog.open(ImportPromptDialogComponent, { data: { fileName: file.name }});
    promptDialog.afterClosed().subscribe(result => {
      if (result) {
        if (file.size > (50 * 1024 * 1024)) {
            this.snackbar.open(this.trans.instant('admin.web.file-too-large') + 'Max: 50MB.', this.trans.instant('admin.web.close'));
            return;
        }
        let metaData: ImportFileMetaData = new ImportFileMetaData();
        metaData.fileName = file.name;

        let importModel = new FileImportModel();
        importModel.metaData = metaData;
        importModel.importCallBack = (data: ImportFileMetaData) => {
          return this.interfaceService.uploadFile(interfaceFileID, [file], data);
        };
        let dialogRef = this.dialog.open(ImportDialogComponent, { data: importModel });
    }
  });
  }
}
