import { Injectable, Output, EventEmitter }               from '@angular/core';
import { ISortableFilter, SortableFilter, SortDirection }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient } from '@angular/common/http';
import { Observable }               from 'rxjs';
import { map }                      from 'rxjs/operators';
import { CommonService }            from './common.service';
import { IBaseService }             from './service.interface';
import { ClubModel }                from '../common/club.model';
import { CreateClubOnTempTableRequest } from '../common/club-on-temptable-request-model';
import { MediaClubModel } from '../common/media.model';



@Injectable()
export class ClubService implements IBaseService<ClubModel> {

    private _commonService: CommonService;

    // Acts as shared event to handle selected clubs for media distribution
    @Output()
    mediaClubsUpdated = new EventEmitter();

    constructor(private http: HttpClient, private globals: Globals) {
        this._commonService = new CommonService(http, globals, 'club');
    }

    public getDetail(id: string): Observable<ClubModel> {
        throw new Error('Method not implemented.');
    }

    public getList(activeClubsOnly: boolean): Observable<ClubModel[]> {
        return this._commonService.get('?activeOnly=' + activeClubsOnly).share();
    }

    public getForUser(userId: number): Observable<ClubModel[]> {
        return this._commonService.get('getClubsForUser/' + userId);
    }

    public select(filter: ISortableFilter): Observable<any> {
        return this._commonService.post('search', filter);
    }

    public getClub(id: number): Observable<any> {
        return this._commonService.get('detail/' + id).pipe(map(o => {
            return o;
        }));
    }

    public insertItem(item: ClubModel): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: ClubModel): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }

    public getPdf(id, type: string, desiredName: string): Observable<any> {
        return this._commonService.get('pdf/' + id + '?type=' + type + '&desiredName=' + desiredName);
    }

    public create(name: string, userIDs: any[]): Observable<any> {
        let body: any = {
            name: name,
            userIDs: userIDs
        };
        return this._commonService.post('create', body);
    }

    public createClubOnTempTable(request: CreateClubOnTempTableRequest): Observable<any> {
        return this._commonService.post('createClubOnTempTable', request);
    }

    public addToWhiteList(clubID: string, userIDs: any[]): Observable<any> {
        let body: any = {
            clubID: clubID,
            userIDs: userIDs
        };
        return this._commonService.post('addToWhiteList', body);
    }

    public addToWhiteListOnTempTable(request: CreateClubOnTempTableRequest): Observable<any> {
        return this._commonService.post('AddToWhiteListOnTempTable', request);
    }

    public getUsers(clubID: number): Observable<any> {

        return this._commonService.get('usersForClub/' + clubID);
    }

    public removeWhiteListMembers(clubID: number, userIDs: any[]): Observable<any> {
        return this._commonService.post('removeWhiteListMembers/' + clubID , userIDs);
    }

    public clearWhiteList(clubID: string): Observable<any> {
        return this._commonService.get('clearWhiteList/' + clubID);
    }

    public suggest(searchString: string, maxCount: number): Observable<any> {
        return this._commonService.get('suggest/' + searchString + '?maxCount=' + maxCount);
    }
}
