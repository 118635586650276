import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from '@ag-grid-enterprise/all-modules';

// import './polyfills';

// depending on the env mode, enable prod mode or add debugging modules
if (environment.production) {
  enableProdMode();
} {
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));

export function isIE() {
      // tslint:disable-next-line:max-line-length
      // if(/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) document.write('<script type="text/javascript" src="/promise.min.js""><\/script>');
      let ua = window.navigator.userAgent;
      let msie = ua.indexOf('MSIE ');
      let trident = ua.indexOf('Trident/');
      let ieVer = -1;
      if (msie > 0) {
        // IE 10 or older => return version number
        ieVer = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      } else if (trident > 0) {
        // IE 11 => return version number
        let rv = ua.indexOf('rv:');
        ieVer = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }
      if (ieVer !== -1 && ieVer <= 11) {
        let userLang = navigator.language; // || navigator.userLanguage;
        // tslint:disable-next-line:max-line-length
        let msg = 'Váš prohlížeč je zastaralý a není podporovaný. Použíjte prosím <a href="https://www.microsoft.com/cs-cz/windows/microsoft-edge">Edge</a>, <a href="https://www.google.com/chrome/">Google Chrome</a>, <a href="https://www.mozilla.org/cs/firefox/new/">Firefox</a> a nebo Safari.';
        if (userLang.indexOf('en') > -1) {
          // tslint:disable-next-line:max-line-length
          msg = 'Your Internet browser is obsolete and not supported anymore. Please use <a href="https://www.microsoft.com/cs-cz/windows/microsoft-edge">Edge</a>, <a href="https://www.google.com/chrome/">Google Chrome</a>, <a href="https://www.mozilla.org/cs/firefox/new/">Firefox</a> or Safari.';
        }
        // document.getElementsByTagName('body')[0].removeChild(document.getElementById('app'));
        document.getElementById('no-ie').style.display = 'block';
        document.getElementById('no-ie').innerHTML = msg;
        return true;
      }
      return false;
}
export function main() {
    console.log('%cAir&Me project', 'background: #FFF; color: #ffa000; font-size: x-large; font-weight: bold');
    console.log('%cSunseed Development s.r.o.\n', 'background: #FFF; color: #8C8C8C; font-size: large');
    console.log('%chttp://www.sunseeddev.com/.\nAll right reserved.', 'background: #FFF; color: #8C8C8C; font-size: 12px');
    console.log('%c\u0054\u006F\u006D \u0043\u0068. \u0072\u0075\u006C\u0065\u007A\u007A\u007A\n', 'background: #FFF; color: #FFF; font-size: 6px');
    // to check if the ag-grid stops to show not licensed message... this should be solved a different way
    // 2020 LicenseManager.setLicenseKey('Sunseed_Development_sro_AIRME_Single_Application_1_Devs__27_January_2021_[v2]_MTYxMTcwNTYwMDAwMA==7526d720a7637c4a132de53bc8cd7359');
    // 2021
    LicenseManager.setLicenseKey('CompanyName=Sunseed Development s.r.o.,LicensedApplication=AIR&ME,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-012721,ExpiryDate=27_January_2022_[v2]_MTY0MzI0MTYwMDAwMA==647fa22c553449dea8b52cf92726a600');
    // return platformBrowserDynamic().bootstrapModule(AppModule);
}

if (!isIE()) {
  if (document.readyState === 'complete') {
    main();
  } else {
    document.addEventListener('DOMContentLoaded', main);
  }
}
