import { Injectable }               from '@angular/core';
import { Globals }                  from '../common/globals';
import { HttpClient }               from '@angular/common/http';
import { Observable, from, of }               from 'rxjs';
import { CommonService }            from './common.service';
import 'rxjs/add/operator/share';
import { InterfaceModel, PortalAgenda } from '../interface/interface.model';
import { catchError } from 'rxjs/operators';
import { InterfaceExportModel } from '../interface/interface-export.model';
import { ImportFileMetaData } from '../common/import-file-metadata.model';
import { toKeyValuePairs } from '../common/functions';

@Injectable()
export class InterfaceService {

    private interfaces = new Observable<InterfaceModel[]>();
    public interfacesArray = new Array<InterfaceModel>();

    private fileOptTimeout = 120000;

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'interface');
    }

    public getAgendaInterfaces(portalAgenda: PortalAgenda): InterfaceModel[] {
        // let s = this.interfaces.toPromise().then(t => t.filter(f.agenda === portalAgenda));
        return this.interfacesArray.filter(i => i.agenda === portalAgenda);
    }

    public export(interfaceID: number, requestModel: InterfaceExportModel): Observable<any> {
        return this._commonService.post('export/' + interfaceID, requestModel, this.fileOptTimeout);
    }

    // public import(interfaceID: number, requestModel: InterfaceExportModel): Observable<any> {
    //     return this._commonService.post('import/' + interfaceID, requestModel, 120);
    // }

    public uploadFile(interfaceID: number, files: File[], metaData: ImportFileMetaData) {
        let postParams = toKeyValuePairs(metaData);
        return this._commonService.uploadFile('import/' + interfaceID, files, null, null, this.fileOptTimeout);
      }

    public async load() {
        let s = await this._commonService.get('listall').share().pipe(catchError(_ => of([]))).toPromise();
        this.interfacesArray = s as Array<InterfaceModel>;
        this.interfaces = from(s) as Observable<InterfaceModel[]>;
    }
}
