import {
    Component,
    ChangeDetectorRef,
    OnInit,
    ChangeDetectionStrategy,
    Optional,
    OnDestroy,
    Inject
} from '@angular/core';
import {
    Router,
    NavigationEnd
} from '@angular/router';
import { AuthService } from './shared/auth.service';
import { Globals } from './common/globals';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { fadeAnimation } from '../style/fade.animation';
import { routerTransition } from '../style/routerTransition.animation';
import { filter } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslationService } from './shared/translation.service';
import { AGGridLicenser } from './common/report-grid/tools/ag-grid-license';
import { InterfaceService } from './shared/interfaces.service';
import { NavItemData } from './common/nav-item.model';
import { CompanyService } from './shared/company.service';
import { Honeycomb } from './shared/honeycomb-api/honeycomb-api';


@Component({
    selector: 'hc-app', // <hc-app></hc-app>
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [fadeAnimation, routerTransition],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements OnInit, OnDestroy {

    reloading: BehaviorSubject<boolean>;

    public currentNavSettings = new NavItemData();
    private subscriptions: Array<Subscription> = [];

    get reloading$(): Observable<boolean> {
        return this.reloading.asObservable();
    }

    public isLoginPage = true;
    public selectedTenantID: number;

    get containerScrollDisabled(): boolean {
        return this.globals.currentNavData().containerScrollDisabled;
    }

    constructor(
        public router: Router,
        private authService: AuthService,
        private globals: Globals,
        private permissionService: NgxPermissionsService,
        private translationService: TranslationService,
        private gridLic: AGGridLicenser,
        private interfaceService: InterfaceService,
        private companyService: CompanyService,
        @Inject('SettingController') private settingController: Honeycomb.Tenant.Admin.IService.SettingController,
        @Optional() private cd: ChangeDetectorRef,
    ) {
        this.reloading = new BehaviorSubject<boolean>(false);
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd))
            .subscribe((e: NavigationEnd) => {
                let url = this.router.url;
                this.isLoginPage = url === '/login';
                this.selectedTenantID = this.globals.getTenantID();
            },
                (err) => console.error(err));

                let sb =  this.globals.updatedBreadcrumb.subscribe(() => {
                    this.currentNavSettings = this.globals.currentNavData();
                    cd.markForCheck();
                },
                (err) => console.error(err));

                this.subscriptions.push(sb);

                let sa =  this.globals.updatedActionRow.subscribe(() => {
                    this.currentNavSettings = this.globals.currentNavData();
                    cd.markForCheck();
                },
                (err) => console.error(err));
                this.subscriptions.push(sa);
    }

    async ngOnInit() {
        this.currentNavSettings = this.globals.currentNavData();

        this.globals.langChanged.subscribe(l => {
            this.translationService.get(l.lang);
        },
            (err) => console.error(err));

        this.globals.forceUpdateContent.subscribe(() => {
            this.reloading.next(true);
            if (!this.cd['destroyed']) {
                this.cd.detectChanges();
            }
            this.reloading.next(false);
            if (!this.cd['destroyed']) {
                this.cd.detectChanges();
            }
        },
            (err) => console.error(err));

        if (this.authService.loggedIn()) {
            this.gridLic.setAGGridLicense();
            this.interfaceService.load();
            await this.companyService.loadCompanies();
        }
    }

    private toggleMenu() {
        this.globals.menuOpen = !this.globals.menuOpen;
        this.globals.toggleMenu.emit();
    }

    logout() {
        this.authService.logout();
    }

    async clearCaches() {
        const entries = Object.values(Honeycomb.Common.ServicePorts).filter(v => typeof v === 'number');
        const ports = entries.map(port => <Honeycomb.Common.Mvc.IApiCaller>{ port });
        await this.settingController.ClearAllCaches(ports).toPromise();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe(),
        (err) => console.error(err));
    }

    isLoginRoute(): boolean {
        return this.router.url === '/login';
    }

    public async onTenantSelected($event) {
        this.authService.setMultiloginTenant($event);
        await this.companyService.loadCompanies();
    }
}
