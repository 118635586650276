import { Component,
         OnInit,
         Inject,
         ChangeDetectorRef}         from '@angular/core';
import { TranslationService }       from '../shared/translation.service';
import { FormControl,
         Validators }               from '@angular/forms';
import { Globals }                  from './globals';
import { ExportLevel }              from '../shared/export-level.enum';
import { HcLocalStorage }           from './local-storage';
import { ExportRequestModel, FileExportResult }       from './export-request-model.model';
import { InterfaceService }         from '../shared/interfaces.service';
import { InterfaceFile,
         InterfaceType,
         ExportContentSelector }    from '../interface/interface.model';
import { Observable, of }               from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNullOrUndefined } from './functions';
import { InfoDialogComponent } from './dialog.info.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { timingSafeEqual } from 'crypto';
import { HttpClient } from '@angular/common/http';
import { delay } from 'rxjs-compat/operator/delay';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'hc-file-export',
    templateUrl: './file-export.component.html',
    styleUrls: ['./file-export.component.scss']
})
export class ExportDialogComponent implements OnInit {

    public model = new ExportRequestModel();

    public dialogTitle;

    public showLimitInput = true;

    public downloadUrl = null;

    public isBackground = false;

    public progressValue = 0;

    public exportLevels = [
        { description: this.trans.instant('admin.web.all'), value: ExportLevel.All },
        { description: this.trans.instant('admin.web.optional'), value: ExportLevel.Optional },
        { description: this.trans.instant('admin.web.required'), value: ExportLevel.Required },
        // { description: this.trans.instant('admin.web.attributes'), value: ExportLevel.Attributes }
    ];

    public exportSelected = [
        { description: this.trans.instant('admin.web.all'), value: ExportContentSelector.All },
        { description: this.trans.instant('admin.web.all-selected'), value: ExportContentSelector.AllSelected },
        { description: this.trans.instant('admin.web.all-unexported'), value: ExportContentSelector.AllUnexported },
        { description: this.trans.instant('admin.web.since-till-date'), value: ExportContentSelector.SinceTillDate },
        { description: this.trans.instant('admin.web.not-set'), value: ExportContentSelector.Undefined },
        // { description: this.trans.instant('admin.web.attributes'), value: ExportLevel.Attributes }
    ];

    public fileFormatControl = new FormControl(null, [Validators.required]);

    public exportLevelControl = new FormControl(this.exportLevels[0], [Validators.required]);

    public exportSelectedControl = new FormControl(this.exportSelected[0], [Validators.required]);

    public interfaces = new Observable<Array<InterfaceFile>>();

    public exportContentSelector = ExportContentSelector;

    public selectedContentSelector: ExportContentSelector;

    public interfaceFiles: Array<InterfaceFile> = [];
    tempLimit: number;

    public get anySelectorAvailable() {
        return this.selectedContentSelector !== ExportContentSelector.Undefined;
    }

    public get ifaceAllSelectedAvailable() {
        // tslint:disable-next-line:no-bitwise
        return this.selectedContentSelector & ExportContentSelector.AllSelected;
    }

    public get ifaceAllAvailable() {
        // tslint:disable-next-line:no-bitwise
        return this.selectedContentSelector & ExportContentSelector.All;
    }

    public get ifaceAllUnexportedAvailable() {
        // tslint:disable-next-line:no-bitwise
        return this.selectedContentSelector & ExportContentSelector.AllUnexported;
    }

    constructor(
        private trans: TranslationService,
        private dialogRef: MatDialogRef<ExportDialogComponent>,
        private interfaceService: InterfaceService,
        private globals: Globals,
        private hcLocalStorage: HcLocalStorage,
        private cd: ChangeDetectorRef,
        private snack: MatSnackBar,
        private dialog: MatDialog,
        private httpClient: HttpClient,
        @Inject(MAT_DIALOG_DATA) public data: ExportRequestModel
    ) {
    }

    async ngOnInit() {
        this.dialogRef.disableClose = true;
        if (!this.data.dialogTitle) {
            this.data.dialogTitle = 'admin.web.export';
        }

        this.exportLevelControl.setValue(ExportLevel.All);

        if (!!this.data.content.ids && this.data.content.ids.length > 0) {
            this.model.content.selector = ExportContentSelector.AllSelected;
            this.exportSelectedControl.setValue(ExportContentSelector.AllSelected);
        } else {
            this.model.content.selector = ExportContentSelector.AllUnexported;
            this.exportSelectedControl.setValue(ExportContentSelector.AllUnexported);
        }

        let res = [];

        this.interfaceService
            .interfacesArray.filter(f => f.agenda === this.data.agenda && f.interfaceType === InterfaceType.Export)
                            .map(m => {
                                m.interfaceFiles.forEach(f => {
                                    f.exportSelector = m.exportSelector;
                                    res.push(f);
                                });
                            });
        this.interfaceFiles = res;

        if (res.length > 0) {
            this.fileFormatControl.setValue(this.interfaceFiles[0].interfaceFileID);
            this.selectedContentSelector = this.interfaceFiles[0].exportSelector;
        }

        this.cd.detectChanges();
    }

    public close() {
        this.dialogRef.close();
    }


    public interfaceFileSelected($event) {
        let iFaceFile = this.interfaceFiles.find(iFace => iFace.interfaceFileID === $event.value);
        this.selectedContentSelector = iFaceFile.exportSelector;
    }

    public async export() {
        this.data.inProgress = true;
        this.hcLocalStorage.lastUsedExportFileFormat = this.fileFormatControl.value;
        this.data.content.selector = this.exportSelectedControl.value;
        this.model.content = this.data.content;
        this.model.selectedExporFileID = this.fileFormatControl.value;
        this.model.level = this.exportLevelControl.value;

        let result = await this.data.exportCallback(this.model)
                                .toPromise()
                                .catch(err => {
                                    this.dialogRef.close();
                                    this.data.inProgress = false;
                                    let msg = this.trans.instant('admin.web.export-failed');
                                    console.error(err);
                                    this.snack.open(msg, this.trans.instant('admin.web.close'), { duration: 2000 });
                                    this.cd.detectChanges();
                                    return;
                                });

        result = <FileExportResult>result;
        if (!!result) {
            if (!isNullOrUndefined(result.errors) && result.errors.length > 0) {

                this.dialog.open(InfoDialogComponent, {
                    data: result.errors[0]
                });
                this.data.inProgress = false;
            }

            let tenantIdParam = '&TenantID=' + this.globals.getTenantID();

            let selectedFormat = this.interfaceFiles.find(ff => ff.interfaceFileID === this.model.selectedExporFileID);

            if (result.backgroudTask) {
                this.isBackground = true;
                let canContinue = false;
                let progressUrl = `${this.globals.GetHostnamePrefix()}/${result.serviceUrl}/ExportStatus/${result.entityDataID}`;
                do {
                    var stat = await this.httpClient.get(progressUrl)
                                .pipe(catchError((err) => { 
                                    console.error('');
                                    return of({ 
                                        exported: 0,
                                        total: 1
                                    }); })) // can be missing for the first request
                                .toPromise() as any;
                    this.progressValue = ( stat.exported / stat.total ) * 100;
                    canContinue = !stat.finished;
                    this.cd.detectChanges();
                    await new Promise(resolve => { setTimeout(resolve, 1000) });
                } while (canContinue)

                let url = `${this.globals.GetHostnamePrefix()}/${result.serviceUrl}/Download/${result.entityDataID}` +
                            '?fileType=' + selectedFormat.type.toString() +
                            '&level=' + this.model.level + tenantIdParam;    
                this.downloadUrl = url;
                location.href = url;
                this.dialogRef.close();
                return;
            }
            this.dialogRef.close();
            let url = this.globals.GetUrlPrefix() + this.data.exportDownloadUrl + result.entityDataID +
                        '?fileType=' + selectedFormat.type.toString() +
                        //'&fileName=export.' + selectedFormat.extension + 
                        '&level=' + this.model.level + tenantIdParam;
            location.href = url;
        }
    }

    public limitCheckChanged(changeEvent: MatCheckboxChange) {
        this.showLimitInput = changeEvent.checked;
        if (changeEvent.checked) {
            this.model.limit = this.tempLimit || this.model.limit;
        } else {
            this.tempLimit = this.model.limit;
            this.model.limit = -1;
        }

    }

    public async getSavedFileFormat() {
        let ifcs = await this.interfaces.toPromise();

        for (let i = 0; i < ifcs.length; i++) {
            if (ifcs[i].type === this.hcLocalStorage.lastUsedExportFileFormat) {
                return ifcs[i];
            }
        }
        return ifcs[0];
    }
}
