import {
    OnInit,
    Component,
    Output,
    Inject, ViewEncapsulation
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { LangRow } from './multilang-input.model';


@Component({
    selector: 'hc-multilang-input-dialog',
    templateUrl: './multilang-input-dialog.component.html',
    styleUrls: ['./multilang-input-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MultilangInputDialogComponent implements OnInit {

    public displayedColumns: string[] = ['lang', 'text'];
    private origTexts: string;

    constructor(
        public dialogRef: MatDialogRef<MultilangInputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public globals: Globals
    ) {
    }

    async ngOnInit() {
        let languages = this.globals.getSupportedLanguages;

        let currentLangs: Array<LangRow> = null;
        if (this.data.inputs) {
            currentLangs = <Array<LangRow>>this.data.inputs.langs;
            this.origTexts = JSON.stringify(currentLangs);
        } else {
            this.data.inputs = {
                langs: []
            };
        }

        if (!currentLangs) {
            currentLangs = [];
        }
        languages.forEach(l => {
            if (currentLangs.findIndex(cl => cl.lang === l) === -1) {
                currentLangs.push({ lang: l, text: '' });
            }
        });
        this.data.inputs.langs = currentLangs;
    }

    public save() {
        this.dialogRef.close(this.data.inputs.langs);
    }

    public close() {
        if (!!this.data.inputs) {
            this.data.inputs.langs = JSON.parse(this.origTexts);
        }
        this.dialogRef.close(null);
    }
}
