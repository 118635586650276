import {
    AfterViewInit,
  ChangeDetectorRef,
    Component,
    ViewChild,
  } from '@angular/core';

import { ICellEditorAngularComp } from 'ag-grid-angular';
import { MultilangInputComponent } from './multilang-input.component';

  @Component({
    selector: 'hc-multilang-input-editor-cell',
    template: `
      <hc-multilang-input style="display: block; width: 100%; min-height: 60px; height: 60px" #multilang [(ngModel)]="text" [simpleInput]="isNew" [locKeyPrefix]="locKeyPrefix" [entityID]="entityID"></hc-multilang-input>
    `
  })
  export class MultilangInputEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;

    public text: any;

    public locKeyPrefix: string;

    public isNew: boolean;

    public entityID: any;

    @ViewChild(MultilangInputComponent, { static: false }) multilang: MultilangInputComponent;

    constructor(
      private cd: ChangeDetectorRef){
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
      window.setTimeout(() => {
        // let el = this.multilang.ele.element.nativeElement as HTMLElement;
        // el.querySelector('input').focus();
      });

      this.multilang.gridApi = this.params.api;
    }

    agInit(params: any): void {
      this.params = params;
      this.text = params.value;
      this.isNew = params.data.isNew;
      this.entityID = this.params.data.backgroundID;
      this.locKeyPrefix = params.colDef.refData.locKeyPrefix;

      if (this.multilang) {
        console.log(this.multilang.dialogClosed);
      }
    }

    getValue(): any {
      this.params.data[this.params.colDef.field] = this.text;

      if (!!this.text && this.text.pureText) {
        this.params.data[this.params.colDef.field].pureText = this.text.pureText;
      }
      if (!!this.text && this.text.langs) {
        this.params.data[this.params.colDef.field].langs = this.text.langs;
      }
      this.cd.detectChanges();
      return this.text;
    }

    isPopup(): boolean {
      return false;
    }

    onKeyDown(event): void {
        event.stopPropagation();
    }
  }
