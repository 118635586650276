import { OnDestroy } from '@angular/core';
import { Globals } from './globals';
import { Subscription ,  Observable } from 'rxjs';
import { NavItemData, NavItem, MenuItemTop } from './nav-item.model';
import { ActivatedRoute } from '@angular/router';
import { SavingDialogComponent } from './saving-dialog.component';
import { isNullOrUndefined } from './functions';
import { MatDialogRef } from '@angular/material/dialog';

export class BaseComponent<TModel> implements OnDestroy {
    public page = 1;
    public pageSize = '10';
    public tabIndex = 0;
    public searchPhrase = '';

    public gridPageChangedSubscription: Subscription;
    public gridPageSizeChangedSubscription: Subscription;
    public tabIndexChangedSubscription: Subscription;
    public dataSavedSubscription: Subscription;

    protected subscriptions: Subscription[] = [];

    public model: TModel = <TModel>{};

    private clonedModel: string;

    public newItem: boolean;

    public get menu(): MenuItemTop {
        return null;
    }

    public menuNew(newCallback: () => void = null): MenuItemTop {
        return MenuItemTop.InitDefaultMenu(newCallback);
    }

    canDeactivate: () => boolean | Observable<boolean>;

    constructor(private baseGlobals: Globals) {
        let navItem = this.baseGlobals.currentNavItem();
        this.gridPageChangedSubscription = baseGlobals.gridPageChanged.subscribe(p => {
             this.page = p;
             navItem.data.gridPage = p;
        },
        (err) => console.error(err));
        this.gridPageSizeChangedSubscription = baseGlobals.gridPageSizeChanged.subscribe(ps => {
            this.pageSize = ps;
            this.page = 1;  // Reset page on page size change
            navItem.data.gridPage = 1;
            let localStorage = this.baseGlobals.getLocalStorage();
            localStorage.setPageSizeForPath(navItem.path, ps);
        },
        (err) => console.error(err));
        this.tabIndexChangedSubscription = baseGlobals.tabIndexChanged.subscribe(ti => {
            this.tabIndex = ti;
            navItem.data.activeTabIndex = ti;
        },
        (err) => console.error(err));

        this.canDeactivate = () => {
            let compareResult = JSON.stringify(this.model) === this.clonedModel;
            return compareResult;
        };

        this.dataSavedSubscription = this.baseGlobals.dataSaved.subscribe(() => {
            this.syncCanDeactivateGuard(); },
            (err) => console.error(err)
        );
        this.loadNavSettings(navItem);
        this.baseGlobals.setMenu(this.menu);
    }

    loadNavSettings(navItem: NavItem): void {
        let ps = this.baseGlobals.getLocalStorage().getPageSizeForPath(navItem.path);
        let searchPhrase = this.baseGlobals.getLocalStorage().getLastUsedSearchPhrase(navItem.path);
        if (!!ps) {
            this.pageSize = ps;
        }
        if (searchPhrase) {
            this.searchPhrase = searchPhrase;
        }
        if (isNullOrUndefined(navItem.data)) {
            navItem.data = new NavItemData();
            return;
        }
        if (Number.isInteger(navItem.data.gridPage)) {
            this.page = navItem.data.gridPage;
        }
        if (Number.isInteger(navItem.data.activeTabIndex)) {
            this.tabIndex = navItem.data.activeTabIndex;
        }
    }

    ngOnDestroy(): void {
        this.gridPageChangedSubscription.unsubscribe();
        this.gridPageSizeChangedSubscription.unsubscribe();
        this.tabIndexChangedSubscription.unsubscribe();
        this.dataSavedSubscription.unsubscribe();

        this.subscriptions.forEach(s => s.unsubscribe());

        this.baseGlobals.setMultiSaveButtons([]);
    }

    public syncCanDeactivateGuard() {
        this.clonedModel = JSON.stringify(this.model);
    }

    public async getIdAsync(route: ActivatedRoute): Promise<any> {
        return new Promise(async (resolve, reject) => {
            route.params.subscribe(routeParams => resolve(routeParams.id), error => reject(error));
        });
    }

    private cloakRef: MatDialogRef<SavingDialogComponent, any> = null;

    public closeCloak() {
        if (this.cloakRef !== null) {
            this.cloakRef.close();
            this.cloakRef = null;
        }
    }

    public openCloak() {
        if (this.cloakRef === null) {
            this.cloakRef = this.baseGlobals.saveDialog.open(SavingDialogComponent);
        }
    }

}
