import { Injectable }               from '@angular/core';
import { ISortableFilter }          from '../common/sortable-filter';
import { Globals }                  from '../common/globals';
import { HttpClient } from '@angular/common/http';
import { Observable }               from 'rxjs';
import { CommonService }            from './common.service';
import { ComponentFilter }          from '../common/component-filter';

@Injectable()
export class ConfigurationService {

    private _commonService: CommonService;

    constructor(http: HttpClient, globals: Globals) {
        this._commonService = new CommonService(http, globals, 'configuration');
    }

    public getItems(filter: ISortableFilter): Observable<any> {
        return this._commonService.get('list', filter);
    }

    public getDetail(id: number): Observable<any> {
        return this._commonService.get('detailAdmin/' + id);
    }

    public perComponentType(filter: ComponentFilter): Observable<any> {
        return this._commonService.get('PerComponentType/' + filter.componentType);
    }

    public perComponentId(filter: ComponentFilter): Observable<any> {
        return this._commonService.get('PerComponentId/' + filter.componentId);
    }

    public perMediaName(mediaName: string): Observable<any> {
        return this._commonService.get('PerMediaName/' + mediaName);
    }

    public checkName(name: string): Observable<any> {
        return this._commonService.get('CheckName/' + encodeURI(name));
    }

    public insertItem(item: any): Observable<any> {
        return this._commonService.insertItem(item);
    }

    public updateItem(id: string, item: any): Observable<any> {
       return this._commonService.updateItem(id, item);
    }

    public deleteItem(id: string): Observable<any> {
        return this._commonService.deleteItem(id);
    }
}
