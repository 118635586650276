import { OnInit,
         Component,
         Output,
         Inject }                           from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable }                       from 'rxjs';
import { TranslationService }               from '../shared/translation.service';
import { FileImportModel } from './file-import.model';
import { isNullOrWhitespace } from './functions';


@Component({
    selector: 'hc-file-import',
    templateUrl: './file-import.component.html',
    styles: ['./file-import.component.scss']
})
export class ImportDialogComponent implements OnInit {

    public result: Observable<any>;
    @Output() responseModel: ImportResponseModel = null;

    constructor(
        private trans: TranslationService,
        public dialogRef: MatDialogRef <ImportDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FileImportModel
    ) {
    }

    async ngOnInit() {
        this.dialogRef.disableClose = true;
        if (this.data && this.data.importCallBack && this.data.metaData) {
            let res = await this.data.importCallBack(this.data.metaData)
                .toPromise()
                .catch(err => {
                    this.onError(err);
                    return null;
                });

            if (res) {
                this.onResponse(res);
            }
        }
    }

    private onResponse(response: string) {
        if (!response) {
            this.responseModel = new ImportResponseModel(true, 0, 0, 0, 0, [this.trans.instant('admin.web.import-error')]);
        } else {
            this.responseModel = ImportResponseModel.deserialize(JSON.parse(response));
            if (this.data && this.data.reloadPage) {
                this.data.reloadPage();
            }
        }
    }

    private onError(error: string) {
        // Error
        if (!isNullOrWhitespace(error)) {
            try {
                this.responseModel = JSON.parse(error);
            } catch { // Contains some HTML exception
                this.responseModel = new ImportResponseModel(true, 0, 0, 0, 0, [this.trans.instant('admin.web.import-error')]);
            }
            return;
        }
        this.responseModel = new ImportResponseModel(true, 0, 0, 0, 0, [this.trans.instant('admin.web.import-error')]);
    }

    private close() {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'hc-import-prompt-dialog',
    template: `
        <div fxLayout="column" style="height: 150px;">
            <p fxFlex>
                {{ promptMessage }}
            </p>
            <div fxFlex="none"  fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
                <button mat-raised-button (click)="clickNo()">{{ 'common.no' | trans }}</button>
                <button mat-raised-button color="primary"  (click)="clickYes()" >{{ 'common.yes' | trans }}</button>
            </div>
        </div>
    `
})
export class ImportPromptDialogComponent {

    public promptMessage: string;

    constructor(
        public dialogRef: MatDialogRef<ImportPromptDialogComponent>,
        private trans: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.promptMessage = this.trans.instant('admin.web.import-prompt');
        if (data.fileName) {
            this.promptMessage += ': ' + data.fileName;
        }
        this.promptMessage += '?';
    }

    clickYes(): void {
        this.data = true;
        this.dialogRef.close(true);
    }

    clickNo(): void {
        this.data = false;
        this.dialogRef.close(false);
    }
}

class ImportResponseModel {

    private _failed: boolean;
    private _read: number;
    private _skipped: number;
    private _inserted: number;
    private _updated: number;
    private _errors: string[];

    constructor(failed: boolean, read: number, skipped: number, inserted: number, updated: number, errors: string[]) {
        this.failed = failed;
        this.read = read;
        this.skipped = skipped;
        this.inserted = inserted;
        this.updated = updated;
        this.errors = errors;
    }

    public static deserialize(serialized: any): ImportResponseModel {
        return new ImportResponseModel(
            serialized.failed,
            serialized.read,
            serialized.skipped,
            serialized.inserted,
            serialized.updated,
            serialized.errors
        );
    }

    public get failed (): boolean {
        return this._failed;
    }

    public set failed(failed: boolean) {
        this._failed = failed;
    }

    public get read (): number {
        return this._read;
    }

    public set read(read: number) {
        this._read = read;
    }

    public get skipped (): number {
        return this._skipped;
    }

    public set skipped(skipped: number) {
        this._skipped = skipped;
    }

    public get inserted (): number {
        return this._inserted;
    }

    public set inserted(inserted: number) {
        this._inserted = inserted;
    }

    public get updated (): number {
        return this._updated;
    }

    public set updated(updated: number) {
        this._updated = updated;
    }

    public get errors (): string[] {
        return this._errors;
    }

    public set errors(errors: string[]) {
        this._errors = errors;
    }
}
